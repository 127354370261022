.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10%;
}
.table {
  border-collapse: collapse;
}
.table td:first-child{
  border-left: 1px solid #ccc;
}
.table td {
  padding: 6px;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.table .content-cell {
  max-width: 450px;
  overflow: auto;
}

.center{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}